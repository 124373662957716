import * as React from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
	Stack,
	Divider,
	Typography,
} from '@mui/material';
import { ConfirmationDialogProps } from '.';
import AdminPersonIcon from '@mui/icons-material/ManageAccounts';
import GppBadIcon from '@mui/icons-material/GppBad';
import InfoIcon from '@mui/icons-material/Info';

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
	open,
	icon,
	title,
	variant,
	description,
	onSubmit,
	onClose,
}) => {
	return (
		<Dialog open={open}>
			<DialogTitle id='alert-dialog-title'>
				<Stack direction={'row'} sx={{ alignContent: 'end' }} gap={0.5}>
					{icon ? icon : ''}
					{title}
				</Stack>
			</DialogTitle>
			<DialogContent sx={{ ml: 1, minWidth: 400, maxWidth: 600 }}>
			
				<DialogContentText  dangerouslySetInnerHTML={{ __html: description }}>
				
				</DialogContentText>
			</DialogContent>
			{/* <Divider sx={{ width: '100%' }}></Divider> */}
			<DialogActions sx={{ width: '100%' }}>
				{variant === 'yes-no' && (
					<>
						<Button size='small' color='primary' variant='text' onClick={onSubmit}>
							YES
						</Button>
						<Button size='small' color='primary' variant='text' onClick={onClose} autoFocus>
							NO
						</Button>
					</>
				)}

				{variant === 'yes-no-cancel' && (
					<>
						<Button size='small' color='primary' variant='text' onClick={onSubmit}>
							YES
						</Button>
						<Button size='small' color='primary' variant='text' onClick={onClose} autoFocus>
							NO
						</Button>
						<Button size='small' color='primary' variant='text' onClick={onClose} autoFocus>
							CANCEL
						</Button>
					</>
				)}

				{variant === 'ok' && (
					<Stack
						direction={'row'}
						sx={{ width: '100%', alignContent: 'flex-end', justifyItems: 'right', justifyContent: 'right' }}
						gap={1}>
						<Button size='small' color='primary' variant='text' onClick={onSubmit}>
							OK
						</Button>
					</Stack>
				)}
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmationDialog;

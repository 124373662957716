/**
 * Compares the provided or current date with a predefined date to check if it's at or after the predefined date
 * @param predefinedDateString 
 * @param currentDateString 
 * @returns 
 */
export function isAtOrAfterPredefinedDate(predefinedDateString: string, currentDateString?: string): boolean {
	let currentDate: Date;
	if (currentDateString) {
		currentDate = new Date(currentDateString);
	} else {
		currentDate = new Date();
	}

	const predefinedDate = new Date(predefinedDateString);

	// Check if the current date is greater than or equal to the predefined date
	return currentDate >= predefinedDate;
}

/** 
 * Checks if the current date is within the range of the start and end dates
 * @param currentDate The current date
 * @param startDate The start date
 * @param endDate The end date
 * @returns True if the current date is within the range of the start and end dates, false otherwise
 * */
export function isDateWithinRange(currentDate: Date, startDate: Date, endDate: Date): boolean {
	return currentDate >= startDate && currentDate <= endDate;
}

/**
 * Calculates the number of days between two dates
 * @param dateString1 The first date
 * @param dateString2 The second date
 * @returns The number of days between the two dates
 */
export function calculateDaysBetweenDates(dateString1: string, dateString2: string): number {
	const date1 = new Date(dateString1);
	const date2 = new Date(dateString2);

	// Calculate the difference in milliseconds
	const differenceMs = Math.abs(date1.getTime() - date2.getTime());

	// Convert milliseconds to days
	const daysDifference = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));

	return daysDifference;
}

interface MonthInfo {
    month: string;
    days: number;
}

/**
 * Gets the months and days between the provided start and end dates
 * @param startDate The start date
 * @param endDate The end date
 * @returns An array of month names and the number of days in each month
 */ 
export function getMonthsAndDaysInRange(startDate: Date, endDate: Date): MonthInfo[] | string | number{
    if (startDate > endDate) {
        return -1;
    }

    const monthsAndDays: MonthInfo[] = [];

    // Loop through each month within the range
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
        const month = currentDate.toLocaleString('default', { month: 'long' });
        const year = currentDate.getFullYear();
        const daysInMonth = new Date(year, currentDate.getMonth() + 1, 0).getDate();

        monthsAndDays.push({ month, days: daysInMonth });

        // Move to the next month
        currentDate.setMonth(currentDate.getMonth() + 1);
        currentDate.setDate(1); // Set the date to the 1st to avoid skipping months
    }

    return monthsAndDays;
}

export function formatDate(date: string): string {
   if(!date) return '';
   let dateObj = new Date(date);
    return (dateObj.getMonth() + 1) +'/'+dateObj.getDate()+'/'+dateObj.getFullYear();
}

export const convertUTCDateToISOString = (value: Date): string => {
	// Converts JS Date to ISO string but doesn't change date/time to client timezone (unlike JS toISOString())
	var isoDateString =
		value.getFullYear() +
		'-' +
		(value.getMonth() + 1 < 10 ? '0' + (value.getMonth() + 1) : value.getMonth() + 1) +
		'-' +
		(value.getDate() < 10 ? '0' + value.getDate() : value.getDate()) +
		'T' +
		(value.getHours() < 10 ? '0' + value.getHours() : value.getHours()) +
		':' +
		(value.getMinutes() < 10 ? '0' + value.getMinutes() : value.getMinutes()) +
		':' +
		(value.getSeconds() < 10 ? '0' + value.getSeconds() : value.getSeconds()) +
		'Z';

	return isoDateString;
};

export const convertUTCDateToTimezone = (date: any, timeZone = 'America/Los_Angeles'): Date => {
	// date     = JS Date or ISO formatted date string '2011-10-05T14:48:00.000Z'
	// timezone = valid JS timezone string: 'America/Los_Angeles'

	if (date instanceof Date) {
		date = convertUTCDateToISOString(date);
	}

	date = date.includes('Z') ? date : date + 'Z';
	date = new Date(
		new Date(new Date(date)).toLocaleString('en-US', {
			timeZone,
		})
	);

	return date;
};
export const getOffByTimeZone = (timeZone, date = new Date()): number => {
	const tz = date.toLocaleString('en', { timeZone, timeStyle: 'long' }).split(' ').slice(-1);
	const dateString = date.toString();
	return (Date.parse(`${dateString} UTC`) - Date.parse(`${dateString} ${tz}`)) / 60 / 1000;
};

export const getUTCDateString = (date = new Date()): string => {
	const pstOffSet = getOffByTimeZone('America/Los_Angeles');
	var dateString =
		date.getFullYear().toString() +
		'-' +
		(date.getMonth() + 1).toString().padStart(2, '0') +
		'-' +
		date.getDate().toString().padStart(2, '0') +
		'T' +
		date.getHours().toString().padStart(2, '0') +
		':' +
		date.getMinutes().toString().padStart(2, '0') +
		':' +
		date.getSeconds().toString().padStart(2, '0') +
		(pstOffSet > 0 ? '+' : '-') +
		(pstOffSet / 60).toString().replace('-', '').padStart(2, '0') +
		':00';

	return new Date(dateString).toISOString();
};

export const isDateInRange = (from: Date, to: Date): boolean => {
	const currentDate = convertUTCDateToTimezone(new Date().toISOString());
	return currentDate >= from && currentDate <= to;
};